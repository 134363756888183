/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import MuxPlayer from '@mux/mux-player-react';
import _debounce from 'lodash/debounce'; // Import the debounce function
import { Tooltip } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  convertMSToSeconds, convertSecondsToMS,
  doesVisitorIdExistInRoom, getVisitorId, getRoomSlugFromUrl,
} from './utils';
import RoomAPI from '../../apis/room';
import AuthAPI from '../../apis/auth';
import DatesAPI from '../../apis/dates';
import { GET_SERVER_EVENTS_INTERVAL, PLAYING_STATUS } from './constants';
import {
  buildProfileUserName, deepEqual, getCurrentDateTime, once,
} from '../../utils/general';
import Spinner from '../common/Spinner';
import routes from '../../routes';
import Analytics, { EVENT_NAMES } from '../../utils/analytics';
import BeforeJoiningModal from './BeforeJoiningModal';

// TODO: add debunce
function Show() {
  const muxPlayerRef = useRef(null);
  const [roomData, setRoomData] = useState(null);
  const roomDataRef = useRef(null);
  const [userData, setUserData] = useState(null);
  const userDataRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [fingerPrint, setFingerprint] = useState(null);
  const [isBeforeJoiningModalOpen, setIsBeforeJoiningModalOpen] = useState(false);
  const [playerState, setPlayerState] = useState({
    videoPlayingStatus: PLAYING_STATUS.PAUSED,
    currentSeekTime: 0,
  });
  const playerStateRef = useRef(playerState);
  const shouldSyncToServerRef = useRef(true);
  const roomSlug = getRoomSlugFromUrl();
  const isGuestRef = useRef(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const getRoom = async () => {
    try {
      const { data } = await RoomAPI.getRoom(roomSlug);
      return data;
    } catch (err) {
      return null;
    }
  };

  useEffect(() => {
    // Show the before joining modal if user comes directly to room.
    // This will fix DomException play() error.
    if (searchParams.get('source') === 'invitation') {
      setIsBeforeJoiningModalOpen(true);
    }
  }, [searchParams]);

  useEffect(() => {
    roomDataRef.current = roomData;
    userDataRef.current = userData;
  }, [roomData, userData]);

  const addCurrentJoiner = async (room, user) => {
    isGuestRef.current = !user?.id;
    const joinerExists = room.attributes.joiners.some(
      (joiner) => joiner.user_id === Number(user?.id),
    );

    if (!isGuestRef.current && joinerExists) return;

    RoomAPI.addJoiner({
      roomSlug,
      userId: Number(user?.id),
      userName: user?.attributes?.name || 'Guest',
      joiningTime: getCurrentDateTime(),
      existingJoiners: room.attributes.joiners,
    });
  };

  const removeCurrentJoiner = () => {
    const currentJoiners = roomDataRef.current?.attributes?.joiners;

    if (currentJoiners?.length === 0) return;

    let updatedJoiners;
    if (isGuestRef.current) {
      const indexToRemove = currentJoiners.findIndex((joiner) => joiner.user_id === null);
      updatedJoiners = [...currentJoiners];
      if (indexToRemove !== -1) {
        updatedJoiners.splice(indexToRemove, 1);
      }
    } else {
      updatedJoiners = currentJoiners
        .filter((joiner) => joiner.user_id !== Number(userDataRef.current.id));
    }

    RoomAPI.removeJoiner({
      roomSlug,
      requestBody: {
        joiners: updatedJoiners,
      },
    });
  };

  const addCurrentVisitor = async (room) => {
    const visitorId = getVisitorId();
    const visitorExists = doesVisitorIdExistInRoom(visitorId, room);
    if (room.attributes.is_expired) return;
    if (visitorExists) return;

    RoomAPI.addVisitor({ roomSlug, visitorId });
  };

  const startDate = (room) => {
    if (
      room.attributes.dream_date.data.attributes.relationships_dream_date.data.attributes.started_at
    ) {
      return;
    }
    DatesAPI.startDate(room.attributes.dream_date.data.attributes.relationships_dream_date.data.id);
  };

  useEffect(() => {
    const id = getVisitorId();
    setFingerprint(id);
  }, []);

  const sendAnalytics = useCallback(once((room) => {
    Analytics.track(
      EVENT_NAMES.ROOM_VIEW_VIDEO_DATE_ROOM,
      Analytics.buildDateProperties(room[0].attributes.dream_date.data),
    );
  }), []);

  useEffect(() => {
    if (roomData) {
      sendAnalytics(roomData);
    }
  }, [roomData]);

  useEffect(() => {
    Promise.all([RoomAPI.getRoom(roomSlug), AuthAPI.getProfile()])
      .then(([roomResponseData, userResponseData]) => {
        setRoomData(roomResponseData?.data?.data);
        setUserData(userResponseData?.data?.data);
        addCurrentJoiner(roomResponseData?.data?.data, userResponseData?.data?.data);
        addCurrentVisitor(roomResponseData?.data?.data);
        startDate(roomResponseData?.data?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      removeCurrentJoiner();
      return '';
    };
    const handlePopState = () => removeCurrentJoiner();

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    playerStateRef.current = playerState;
  });

  const handlePlay = useCallback(() => {
    if (shouldSyncToServerRef.current === false) return;
    RoomAPI.syncEvents({
      roomSlug,
      videoPlayingStatus: PLAYING_STATUS.PLAYING,
    });
  }, []);

  const handlePause = useCallback(() => {
    if (shouldSyncToServerRef.current === false) return;
    RoomAPI.syncEvents({
      roomSlug,
      videoPlayingStatus: PLAYING_STATUS.PAUSED,
      currentVideoSeekMs: convertSecondsToMS(muxPlayerRef.current.currentTime),
    });
  }, []);

  const handleSeeked = useCallback(() => {
    if (shouldSyncToServerRef.current === false) return;

    muxPlayerRef?.current.pause();
    RoomAPI.syncEvents({
      roomSlug,
      currentVideoSeekMs: convertSecondsToMS(muxPlayerRef.current.currentTime),
      videoPlayingStatus: PLAYING_STATUS.PAUSED,
    });
  }, []);

  const handleEnded = useCallback(() => {
    RoomAPI.syncEvents({
      roomSlug, currentVideoSeekMs: 0, videoPlayingStatus: PLAYING_STATUS.ENDED,
    });
  }, []);

  const registerPlayerEvents = () => {
    muxPlayerRef?.current?.addEventListener('play', handlePlay);
    muxPlayerRef?.current?.addEventListener('pause', handlePause);
    muxPlayerRef?.current?.addEventListener('seeked', handleSeeked);
    muxPlayerRef?.current?.addEventListener('ended', handleEnded);
  };

  const removePlayerEvents = () => {
    muxPlayerRef?.current?.removeEventListener('play', handlePlay);
    muxPlayerRef?.current?.removeEventListener('pause', handlePause);
    muxPlayerRef?.current?.removeEventListener('seeked', handleSeeked);
    muxPlayerRef?.current?.removeEventListener('ended', handleEnded);
  };

  const getServerEvents = async () => {
    const data = await getRoom();
    if (data === null) return;

    if (!deepEqual(data.data.attributes.joiners, roomData.attributes.joiners)) {
      setRoomData(data.data);
    }

    const newPlayerState = {
      videoPlayingStatus: data.data.attributes.video_playing_status,
      currentSeekTime: data.data.attributes.current_video_seek,
    };

    if (deepEqual(playerStateRef.current, newPlayerState)) {
      return;
    }

    shouldSyncToServerRef.current = false;

    try {
      if (newPlayerState.videoPlayingStatus === PLAYING_STATUS.PLAYING) {
        await muxPlayerRef?.current.play();
      } else if (newPlayerState.videoPlayingStatus === PLAYING_STATUS.PAUSED) {
        await muxPlayerRef?.current.pause();
      }
    } catch (err) {
      console.error(err);
    }

    if (playerStateRef.current.currentSeekTime !== newPlayerState.currentSeekTime) {
      muxPlayerRef.current.currentTime = convertMSToSeconds(newPlayerState.currentSeekTime);
    }

    setTimeout(() => { shouldSyncToServerRef.current = true; }, 300);

    setPlayerState(newPlayerState);
  };

  useEffect(() => {
    if (roomData?.attributes?.room_background_image_url === null) return;
    const imageLoader = new Image();
    imageLoader.src = `${roomData?.attributes?.room_background_image_url}}`;
    imageLoader.onload = () => {
      setIsLoading(false);
    };
  }, [roomData?.attributes?.room_background_image_url]);

  useEffect(() => {
    if (isLoading) return undefined;
    registerPlayerEvents();
    const intervalId = setInterval(getServerEvents, GET_SERVER_EVENTS_INTERVAL);

    return () => {
      clearInterval(intervalId);
      removePlayerEvents();
    };
  }, [isLoading]);

  useEffect(() => {
    // if any user reloads restarts
    RoomAPI.syncEvents({
      roomSlug,
      videoPlayingStatus: PLAYING_STATUS.PAUSED,
      currentVideoSeekMs: 0,
    });
  }, []);

  if (isLoading) return <Spinner className="!absolute !top-1/2 !left-1/2" />;

  if (
    (fingerPrint && (doesVisitorIdExistInRoom(fingerPrint, roomData) === false))
    && roomData?.attributes?.is_expired
  ) {
    return navigate(`/${routes.tooManyAttempts.index}`);
  }

  return (
    <div className="p-10 h-full bg-cover" style={{ backgroundImage: `url(${roomData?.attributes?.room_background_image_url})` }}>
      <div className="w-full flex justify-end gap-2 h-[50px]">
        {
          roomData?.attributes?.joiners.map((user) => (
            <Tooltip title={user.user_name}>
              <span
                className="bg-gray-800 text-2xl font-bold border border-white rounded-full w-13 h-13 text-white flex justify-center items-center cursor-default"
              >
                {buildProfileUserName(user.user_name)}
              </span>
            </Tooltip>
          ))
        }
      </div>
      <div className="flex justify-center items-center flex-col mux-container-height -mt-[50px] lg:mt-0">
        <div className="max-w-6xl w-full">
          <MuxPlayer
            autoPlay={false}
            ref={muxPlayerRef}
            streamType="on-demand"
            playbackId={roomData?.attributes?.dream_date?.data?.attributes?.mux_playback_id}
            metadataVideoTitle="Placeholder (optional)"
            metadataViewerUserId="Placeholder (optional)"
            primaryColor="#FFFFFF"
            secondaryColor="#000000"
          />
        </div>
      </div>
      <BeforeJoiningModal
        isModalOpen={isBeforeJoiningModalOpen}
        setIsModalOpen={setIsBeforeJoiningModalOpen}
      />
    </div>
  );
}

export default Show;
