import One from '../../assets/before-joining/1.png';
import Two from '../../assets/before-joining/2.png';
import Three from '../../assets/before-joining/3.png';

export const PLAYING_STATUS = {
  YET_TO_START: 'yet_to_start',
  PLAYING: 'playing',
  PAUSED: 'paused',
  ENDED: 'ended',
};

export const GET_SERVER_EVENTS_INTERVAL = 1000;

export const CAROUSEL_ITEMS = [
  {
    id: 1,
    image: One,
    heading: '1. Put in headphones, ideally wireless',
    description: 'This ensures the best audio experience, reducing echo/buzz, while still giving you freedom to move around',
  },
  {
    id: 2,
    image: Two,
    heading: '2. Video call your partner from your computer',
    description: 'We recommend Google Meet or Discord for best results, but WhatsApp, FaceTime, etc. will also work',
  },
  {
    id: 3,
    image: Three,
    heading: '3. Adjust window sizes to fit side-by-side',
    description: 'The goal is to see both this browser window and your partner’s face on your screen at the same time',
  },
];
