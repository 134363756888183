/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../Layout';
import Button from '../../common/Button';
import DetailsModal from './DetailsModal';
import SwapDateModal from './SwapDateModal';
import ReviewModal from './ReviewModal';
import DatesApi from '../../../apis/dates';
import RoomApi from '../../../apis/room';
import DateCardRenderer from './DateCardRenderer';
import { preloadImages } from '../../../utils/general';
import { getCardDetailImageUrls, removeBase64Prefix } from './utils';
import Spinner from '../../common/Spinner';
import useGlobalStore from '../../../stores/useGlobalStore';
import routes from '../../../routes';
import { getStripeCheckoutLink } from '../utils';
import { STRIPE_CHECKOUT_LINKS } from '../constants';
import NoSwapsLeftModal from './NoSwapsLeftModal';
import NotEnoughDatesLeftModal from '../NotEnoughDatesLeftModal';
import Analytics, { EVENT_NAMES, PROPERTY_NAMES } from '../../../utils/analytics';

function DatesList() {
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isSwapModalOpen, setIsSwapModalOpen] = useState(false);
  const [isNoSwapLeftModalOpen, setIsNoSwapLeftModalOpen] = useState(false);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [datesData, setDatesData] = useState([]);
  const [isStartDateSubmitting, setIsStartDateSubmitting] = useState(false);
  const userId = useGlobalStore((state) => state.profileData.data.id);
  const setGlobalState = useGlobalStore((state) => state.setGlobalState);
  const [continueWithIsSubmitting, setContinueWithIsSubmitting] = useState(false);
  const [swapDateData, setSwapDateData] = useState(null);
  const [isUnableToBuyMoreDatesOpen, setIsUnableToBuyMoreDatesOpen] = useState(false);
  const [showPurchaseSubscription, setShowPurchaseSubscription] = useState(false);
  const profileData = useGlobalStore((state) => state.profileData);

  const navigate = useNavigate();

  const isPreloadedImagesRef = useRef(false);

  useEffect(() => {
    const fetchDates = async () => {
      try {
        const response = await DatesApi.getDates();
        setDatesData(response.data?.data);
      } catch (err) {
        setShowPurchaseSubscription(true);
      }
    };
    if (datesData.length === 0) {
      fetchDates();
    }
  }, [datesData.length]);

  useEffect(() => {
    Analytics.track(EVENT_NAMES.DASHBOARD_VIEW_DASHBOARD_MAIN_PAGE);
  }, []);

  const refetchData = () => {
    setDatesData([]);
    setGlobalState({ profileData: null });
  };

  const onDetailsClick = (date) => {
    setSelectedDate(date);
    setIsDetailsModalOpen(true);
    Analytics.track(
      EVENT_NAMES.DASHBOARD_CLICK_INTO_DATE_DETAILS,
      Analytics.buildDateProperties(date),
    );
  };

  const onStartDateClick = async (date, { startedFrom }) => {
    setIsStartDateSubmitting(true);
    refetchData();
    let roomSlug = date.attributes.relationships_dream_date.data.attributes.room_slug;
    if (!roomSlug) {
      const { data } = await RoomApi.createRoom({
        relationshipsDreamDateId: date.attributes.relationships_dream_date.data.id,
      });
      roomSlug = data.data.attributes.slug;
    }
    // TODO: remove localstorage and use zustand.
    // TODO: dont store in localstorage and instead pass it as a route param.
    localStorage.setItem('room_slug', roomSlug);
    setGlobalState({ roomSlug });
    setIsStartDateSubmitting(false);
    navigate(routes.room.ready);
    Analytics.track(
      EVENT_NAMES.DASHBOARD_CLICK_START_DATE,
      { ...Analytics.buildDateProperties(date), 'started from': startedFrom === 'card' ? 'dashboard' : 'date detail' },
    );
  };

  const onSwapDateClick = (date) => {
    setIsDetailsModalOpen(false);
    setIsSwapModalOpen(true);
    setSwapDateData(date);
    Analytics.track(
      EVENT_NAMES.DASHBOARD_CLICK_TO_SWAP_DATE,
      Analytics.buildDateProperties(date),
    );
  };

  const onNoSwapsLeftClick = () => {
    setIsDetailsModalOpen(false);
    setIsNoSwapLeftModalOpen(true);
  };

  const onContinueWithSwapClick = async () => {
    setContinueWithIsSubmitting(true);
    await DatesApi.swapDate(Number(swapDateData.attributes.relationships_dream_date.data.id));
    setContinueWithIsSubmitting(false);
    setIsSwapModalOpen(false);
    refetchData();
    Analytics.track(
      EVENT_NAMES.DASHBOARD_CONFIRM_SWAP_DATE,
      Analytics.buildDateProperties(swapDateData),
    );
  };

  const onNevermindClick = () => {
    setIsSwapModalOpen(false);
    Analytics.track(
      EVENT_NAMES.DASHBOARD_DECLINE_SWAP_DATE,
      Analytics.buildDateProperties(swapDateData),
    );
  };

  const onReviewClick = (date) => {
    setSelectedDate(date);
    setIsReviewModalOpen(true);
    Analytics.track(
      EVENT_NAMES.DASHBOARD_CLICK_TO_LEAVE_A_REVIEW,
      Analytics.buildDateProperties(date),
    );
  };

  const onEditReviewClick = (date) => {
    setSelectedDate(date);
    setIsReviewModalOpen(true);
    setIsDetailsModalOpen(false);
  };

  const onSubmitReviewClick = async (values) => {
    await DatesApi.submitReview({
      relationShipDreamDateId: selectedDate.attributes.relationships_dream_date.data.id,
      reviewStars: values.rating,
      reviewText: values.feedback,
      fileName: values.imageName,
      imageBase64: removeBase64Prefix(values.image),
      userId,
    });
    setIsReviewModalOpen(false);
    refetchData();
    // Todo: refactor
    Analytics.track(
      EVENT_NAMES.DASHBOARD_CLICK_TO_SUBMIT_A_REVIEW,
      {
        ...Analytics.buildDateProperties(selectedDate),
        ...{
          'star rating': values.rating,
          'has feedback': !!values.feedback,
          'has photo': !!values.image,
        },
      },
    );
  };

  const onReAccessDateClick = (date) => {
    // TODO: create url builder
    navigate(`/room/${date.attributes.relationships_dream_date.data.attributes.room_slug}`);
  };

  const handleBuyMoreClick = () => {
    Analytics.setSendBeaconAsTransport();
    Analytics.track(
      EVENT_NAMES.DASHBOARD_CLICK_BUY_MORE_DATES,
      { [PROPERTY_NAMES.BUY_FROM]: 'bottom cta' },
    );

    if (profileData?.data?.attributes?.total_dates_left_to_be_alloted_on_positive_likability < 3) {
      setIsUnableToBuyMoreDatesOpen(true);
    } else {
      window.location.href = getStripeCheckoutLink(STRIPE_CHECKOUT_LINKS.PACK_OF_THREE);
    }
  };

  useEffect(() => {
    if (datesData.length > 0 && isPreloadedImagesRef.current === false) {
      preloadImages(getCardDetailImageUrls(datesData));
      isPreloadedImagesRef.current = true;
    }
  }, [datesData.length]);

  return (
    <Layout wrapperClassNames="md:px-8 lg:px-16 max-w-none md:container min-h-screen">
      <Spinner loading={(datesData.length === 0 && !showPurchaseSubscription)} className="!absolute !top-1/2 !left-1/2">
        <div className="mt-10 pb-10 flex flex-wrap gap-x-[1.88rem] lg:gap-x-[4.3rem] gap-y-10 dates-list-page justify-center lg:justify-start">
          {
            datesData?.map((date) => (
              <DateCardRenderer
                date={date}
                onDetailsClick={onDetailsClick}
                onStartDateClick={onStartDateClick}
                onReviewClick={onReviewClick}
                isStartDateSubmitting={isStartDateSubmitting}
              />
            ))
          }
          {showPurchaseSubscription ? (
            <span>
              Thanks for submitting your quiz! To access dates that match your preferences,
              please purchase a date pack using the button in the top right corner.
            </span>
          ) : (
            <div className="bg-white px- rounded-2.5xl py-13 flex flex-col items-center w-full text-3xl shadow-custom-card-light">
              <h3 className="mb-3.7 text-3xl font-bold">Ready for more?</h3>
              <Button secondary onClick={handleBuyMoreClick}>Buy more dates</Button>
            </div>
          )}
        </div>
      </Spinner>
      <DetailsModal
        date={selectedDate}
        isModalOpen={isDetailsModalOpen}
        setIsModalOpen={setIsDetailsModalOpen}
        onStartDateClick={onStartDateClick}
        isStartDateSubmitting={isStartDateSubmitting}
        onSwapDateClick={onSwapDateClick}
        onReAccessDateClick={onReAccessDateClick}
        onNoSwapsLeftClick={onNoSwapsLeftClick}
        onEditReviewClick={onEditReviewClick}
      />
      <SwapDateModal
        isModalOpen={isSwapModalOpen}
        setIsModalOpen={setIsSwapModalOpen}
        isSubmitting={continueWithIsSubmitting}
        onContinueWithSwapClick={onContinueWithSwapClick}
        onNevermindClick={onNevermindClick}
      />
      <NoSwapsLeftModal
        isModalOpen={isNoSwapLeftModalOpen}
        setIsModalOpen={setIsNoSwapLeftModalOpen}
      />
      <ReviewModal
        date={selectedDate}
        isModalOpen={isReviewModalOpen}
        setIsModalOpen={setIsReviewModalOpen}
        onSubmitReviewClick={onSubmitReviewClick}
      />
      <NotEnoughDatesLeftModal
        isModalOpen={isUnableToBuyMoreDatesOpen}
        setIsModalOpen={setIsUnableToBuyMoreDatesOpen}
      />
    </Layout>
  );
}

export default DatesList;
