import { Carousel, Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import Button from '../common/Button';
import { CAROUSEL_ITEMS } from './constants';

function BeforeJoiningModal({ isModalOpen, setIsModalOpen }) {
  const carouselRef = useRef(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  useEffect(() => {
    localStorage.setItem('before_joining_modal_shown', true);
  }, []);

  const handleNext = () => {
    if (CAROUSEL_ITEMS.length - 1 === currentSlideIndex) {
      return setIsModalOpen(false);
    }
    return carouselRef.current?.innerSlider.slickNext();
  };

  return (
    <Modal
      width="41rem"
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={null}
      closable={false}
      maskClosable={false}
      className="before-joining"
      centered
    >
      <Carousel
        adaptiveHeight
        beforeChange={(_, next) => setCurrentSlideIndex(next)}
        ref={carouselRef}
        dotPosition="bottom"
        infinite={false}
      >
        {CAROUSEL_ITEMS.map((item) => (
          <div key={item.id}>
            <img src={item.image} alt="" className="rounded-t-xl object-cover h-72" />
            <div className="p-3.5 pb-0">
              <p className="text-custom-pink font-bold text-lg">LONG DISTANCE DATE NOTES (IGNORE IF IN PERSON)</p>
              <h2 className="my-2.5 font-bold text-2xl">{item.heading}</h2>
              <p className="text-2xl">{item.description}</p>
            </div>
          </div>
        ))}
      </Carousel>
      <div className="flex justify-end pb-3.5 mt-3.7">
        <Button
          secondary
          className="mr-7.5 z-50"
          onClick={handleNext}
        >
          Next
        </Button>
      </div>
    </Modal>
  );
}

export default BeforeJoiningModal;
